<template>
	<div class="container">
		<div class="status flex f-d-c f-ai-c f-jc-c">
			<img :src="require('@/assets/images/success.png')" alt="">
			<p>等待处理</p>
			<span>已提交申请，等待客服处理</span>
            <span v-if="serviceType === 'smb'" class="smb-tips">本次为服务使用申请，如您需申请理赔，请您至平安健康保险app申请理赔。</span>
		</div>
	</div>
</template>
<script>
export default {
	name: 'Success',
	data () {
		return {
			serviceType: ''
		};
	},
	created () {
		this.serviceType = this.$route.query.type;
	}
};
</script>
<style lang="less" scoped>
	.container {
		padding: 12px;
		background-color: #F2F5F7;
		position: absolute;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;
		.status {
			// width: 351px;
			height: 279px;
			background: #FFFFFF;
			border-radius: 8px;
			img {
				width: 80px;
				height: 80px;
			}
			p {
				font-size: 17px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #202020;
				margin-top: 20px;
				margin-bottom: 14px;
			}
			span {
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #4D4D4D;
			}
            .smb-tips {
                width: 90vw;
                font-size: 16px;
                margin-top: 10px;
                text-align: center;
                color: #FF9938;
                font-weight: 600;
            }
		}
	}
</style>