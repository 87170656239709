var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "status flex f-d-c f-ai-c f-jc-c" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/success.png"), alt: "" },
      }),
      _c("p", [_vm._v("等待处理")]),
      _c("span", [_vm._v("已提交申请，等待客服处理")]),
      _vm.serviceType === "smb"
        ? _c("span", { staticClass: "smb-tips" }, [
            _vm._v(
              "本次为服务使用申请，如您需申请理赔，请您至平安健康保险app申请理赔。"
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }